/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Box, Grid, Typography, List, ListItem, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import DiscoverImage from '../assets/imagescta-moc-1-1.png';

// Keyframe animation as a global style component
function GlobalStylesComponent() {
  return (
    <style>
      {`
      @keyframes featureImgBounce {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-30px);
        }
      }
    `}
    </style>
  );
}

function Discovermore() {
  return (
    <Box
      component="section"
      sx={{
        width: '100%',
        paddingTop: '155px',
        paddingBottom: '40px',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <GlobalStylesComponent />

      <Box
        component="div"
        sx={{
          position: 'relative',
          width: '1200px',
          display: 'flex',
          flexDirection: 'row-reverse', // Default to row
          alignItems: 'center',
          justifyContent: 'space-between',
          '@media (max-width:1200px)': {
            width: '100%',
            padding: '0 20px 0 0',
          },

          '@media (max-width:1000px)': {
            flexDirection: 'column', // Stack in a column on smaller screens
            alignItems: 'center',
          },
          '@media (max-width:600px)': {
            padding: '0',
          },
        }}
      >
        {/* Image Section */}
        <Box
          component="img"
          src={DiscoverImage}
          alt="Awesome Image"
          sx={{
            width: '100%',
            maxWidth: '600px',
            animation: 'featureImgBounce 5s ease-in-out 0s infinite alternate',
            zIndex: 10,
            marginBottom: '20px',
            '@media (max-width:1200px)': {
              position: 'relative', // Remove absolute positioning for smaller screens
              top: 'unset',
              right: 'unset',
              marginBottom: '40px',
            },
            '@media (max-width:1000px)': {
              width: '510px',
            },
            '@media (max-width:600px)': {
              width: '100%',
              padding: '0 20px',
            },
          }}
        />

        {/* Text Section */}
        <Box
          sx={{
            padding: '0 20px',
            maxWidth: '600px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            '@media (max-width:1000px)': {
              width: '510px',
            },
            '@media (max-width:600px)': {
              width: '100%',
              padding: '0 20px',
            },
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: '60px',
              lineHeight: '75px',
              textTransform: 'uppercase',
              fontWeight: '700',
              color: '#1b2143',
              '@media (max-width:1200px)': {
                fontSize: '38px',
                lineHeight: '48px',
                textAlign: 'center',
              },
              '@media (max-width:500px)': {
                fontSize: '28px',
                lineHeight: '38px',
              },
            }}
          >
            Take a control on <br />
            your mobile app
          </Typography>
          <List
            sx={{
              margin: 0,
              padding: 0,
              listStyle: 'none',
              marginTop: '40px',
              marginBottom: '50px',
              '@media (max-width:1200px)': {
                textAlign: 'center',
              },
            }}
          >
            {['Coaches and Trainers.', 'Supplements.', 'Nutritions.', 'Healthy Meals.'].map(
              (item, index) => (
                <ListItem
                  key={index}
                  sx={{
                    position: 'relative',
                    color: '#6d6d6d',
                    fontSize: '18px',
                    paddingLeft: '34px',
                    fontWeight: '400',
                    marginTop: index > 0 ? '5px' : '0',
                    lineHeight: '34px',
                    padding: '0 0 0 34px',
                    '@media (max-width:1200px)': {
                      paddingLeft: '50px',
                      textAlign: 'center',
                    },
                  }}
                >
                  <CheckIcon
                    sx={{
                      fontSize: '18px',
                      color: '#f8b426',
                      position: 'absolute',
                      top: '50%',
                      left: 0,
                      transform: 'translateY(-50%)',
                      '@media (max-width:1200px)': {
                        left: 'calc(50% - 100px)', // Center the icon on smaller screens
                      },
                    }}
                  />
                  {item}
                </ListItem>
              ),
            )}
          </List>

          <Button
            href="#"
            variant="contained"
            sx={{
              width: 'auto',
              backgroundColor: '#f8b426',
              color: '#1b2143',
              fontWeight: '700',
              fontSize: '14px',
              lineHeight: '34px',
              borderRadius: '30.5px',
              padding: '13.5px 50px',
              display: 'block',
              margin: '0 0',
              '&:hover': {
                backgroundColor: '#1b2143',
                color: '#fff',
              },
              '@media (max-width:450px)': {
                padding: '11px 35px',
              },
            }}
          >
            Discover more
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Discovermore;
