/* eslint-disable react/no-array-index-key */
import { Box, Typography } from '@mui/material';
import React from 'react';

const termsData = [
  {
    title: '1. Services Provided',
    content: [
      `Our nutrition coaching services are designed to support your overall health and wellness. However, they do not replace medical advice, diagnosis, or treatment from healthcare providers.`,
      `Our services include personalized meal plans, nutrition education, and lifestyle advice, aimed at helping you achieve your health goals. These services are provided based on the information you give us.`,
      `You understand that we are not licensed healthcare professionals, and the services are provided solely for educational purposes. Always seek advice from your doctor before making any changes to your diet.`,
    ],
  },
  {
    title: '2. Client Responsibilities',
    content: [
      `As a client, you must provide accurate and complete information regarding your health, diet, and lifestyle. This ensures we can offer the best guidance for your situation.`,
      `You agree to follow the nutritional recommendations provided and to notify us of any changes in your health status that may affect the advice given.`,
      `Failure to comply with the program's requirements or any advice provided is entirely your responsibility, and we cannot be held liable for the outcomes.`,
    ],
  },
  {
    title: '3. Not Medical Advice',
    content: [
      `Our nutrition coaching services are not intended to replace professional medical advice. Always consult your healthcare provider before starting any new diet or exercise program.`,
      `The information provided by our coaches is for educational purposes only and is not a substitute for professional medical guidance. It’s important to have regular check-ups with your doctor when undergoing significant lifestyle changes.`,
      `If you have a medical condition or are taking medications, it’s crucial to seek approval from your healthcare provider before adopting any nutrition or fitness recommendations.`,
    ],
  },
  {
    title: '4. Payment and Fees',
    content: [
      `All payments must be made in full before the start of the nutrition program. Payments can be made via credit card, bank transfer, or other accepted methods.`,
      `No refunds will be issued once the program has started, except in extenuating circumstances as decided on a case-by-case basis.`,
      `Late payments may result in suspension or cancellation of services until full payment is received. Clients are responsible for ensuring timely payment.`,
    ],
  },
  {
    title: '5. Refund policy',
    content: ['', <br />, <br />, ''],
  },
  {
    title: '6. Appointment and Cancellations',
    content: [
      `Appointments must be scheduled in advance, and clients are expected to be punctual. Missed appointments without prior notice may incur a cancellation fee.`,
      `We require a minimum of 24 hours’ notice to reschedule or cancel an appointment. Failure to do so will result in a charge for the missed session.`,
      `We reserve the right to reschedule or cancel appointments due to unforeseen circumstances, though we will make every effort to provide adequate notice in such cases.`,
    ],
  },
  {
    title: '7. Results Disclaimer',
    content: [
      `While we are committed to helping you achieve your goals, we cannot guarantee specific results from our coaching services as outcomes depend on various factors.`,
      `Your commitment to the program, consistency in following recommendations, and individual factors like genetics and lifestyle choices all impact your results.`,
      `We do not claim that our coaching will prevent, treat, or cure any disease or health condition. Each client’s progress is unique, and results may vary.`,
    ],
  },
  {
    title: '8. Confidentiality and Privacy',
    content: [
      `Your privacy is important to us. All personal and health information provided during the coaching process will be kept confidential and will not be shared without your consent.`,
      `We comply with all applicable data protection and privacy laws. You have the right to access and request the correction of any personal information we hold about you.`,
      `In certain cases, we may be required to disclose your information if it is required by law, such as in the case of a court order or to prevent harm.`,
    ],
  },
  {
    title: '9. Intellectual Property',
    content: [
      `All content, materials, and resources provided as part of the nutrition coaching services are the intellectual property of our business.`,
      `You may not reproduce, distribute, or use the materials provided without our express written consent. This includes meal plans, guides, and other resources shared during the coaching.`,
      `Unauthorized use of our intellectual property may result in legal action to protect our rights and ensure compliance with these terms.`,
    ],
  },
  {
    title: '10. Limitation of Liability',
    content: [
      `We are not liable for any direct, indirect, incidental, or consequential damages that may arise from your use of our nutrition coaching services.`,
      `This includes, but is not limited to, any injury, illness, or adverse effects that may occur as a result of following the nutrition advice provided.`,
      `You agree to assume full responsibility for your health and wellness decisions, and you release us from any claims or liabilities related to your participation in the coaching program.`,
    ],
  },
  {
    title: '11. Indemnification',
    content: [
      `You agree to indemnify and hold us harmless from any claims, damages, or losses arising from your use of our services or breach of these terms.`,
      `This indemnification extends to any third-party claims resulting from your actions or omissions during your participation in the nutrition coaching program.`,
      `We reserve the right to seek legal remedies in the event of any disputes or violations of these terms.`,
    ],
  },
  {
    title: '12. Changes to Terms and Conditions',
    content: [
      `We reserve the right to update or modify these Terms and Conditions at any time without prior notice. Any changes will be effective immediately upon posting.`,
      `We encourage clients to periodically review these Terms to stay informed of any updates. Continued use of the services after modifications constitutes acceptance of the new terms.`,
      `If you do not agree with any changes to these Terms, you may discontinue use of our services.`,
    ],
  },
  {
    title: '13. Governing Law',
    content: [
      `These Terms and Conditions are governed by the laws of [Your Jurisdiction], and any disputes will be resolved in accordance with the laws of that jurisdiction.`,
      `In the event of any legal disputes, the parties agree to submit to the exclusive jurisdiction of the courts in [Your Jurisdiction].`,
      `By using our services, you agree to abide by the laws of the governing jurisdiction and these Terms and Conditions.`,
    ],
  },
];
function Termsconditions() {
  return (
    <Box width="100%" display="flex" alignItems="center" justifyContent="center">
      <Box width="100%" padding="50px 20px" maxWidth="1200px">
        <Typography
          variant="h4"
          fontSize="60px"
          fontWeight="700"
          marginBottom="30px"
          textAlign="center"
          color="#1b2143"
          sx={{
            '@media (max-width:800px)': {
              fontSize: '38px',
            },
            '@media (max-width:400px)': {
              fontSize: '28px',
            },
          }}
        >
          Terms and Conditions
        </Typography>

        {/* Map over termsData to render each section with multiple contents */}
        {termsData.map((section, index) => (
          <Box key={index} marginBottom="20px">
            <Typography variant="h6" fontWeight="600" marginBottom="10px">
              {section.title}
            </Typography>

            {section.content.map((paragraph, idx) => (
              <Typography key={idx} variant="body1" color="textSecondary" marginBottom="10px">
                {paragraph}
              </Typography>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default Termsconditions;
