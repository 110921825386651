import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function BackToTop() {
  const [visible, setVisible] = useState(false);

  // Show button when page is scrolled down by 300px
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  // Scroll to top when button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '45px',
        right: '45px',
        zIndex: '1000',
        display: visible ? 'block' : 'none', // Show/Hide based on scroll
      }}
    >
      <IconButton
        onClick={scrollToTop}
        sx={{
          bgcolor: '#2f4099', // Background color for the button
          color: 'white', // Icon color
          '&:hover': {
            backgroundColor: '#f8b426', // Darker blue on hover
          },
        }}
      >
        <KeyboardArrowUpIcon />
      </IconButton>
    </Box>
  );
}

export default BackToTop;
