/* eslint-disable react/no-array-index-key */
import { Box, Typography } from '@mui/material';
import React from 'react';

// Sample privacy policy data structure with multiple content sections per heading
const privacyData = [
  {
    title: '1. Information Collection',
    content: [
      `We collect personal information that you voluntarily provide to us when you sign up for our services, such as your name, email address, and health-related details.`,
      `We may also collect additional information such as payment details when you subscribe to our services.`,
      `In addition to personal data, we gather technical data such as your IP address, browser type, and access times to improve our website's performance.`,
    ],
  },
  {
    title: '2. Use of Information',
    content: [
      `The information we collect is used to provide and improve our services. This includes developing personalized nutrition plans and communication regarding program updates.`,
      `We may use your email address to send promotional materials, newsletters, and service updates. You can opt out of receiving these communications at any time.`,
      `Data collected from technical means like cookies helps us enhance your user experience by personalizing content and features to suit your preferences.`,
    ],
  },
  {
    title: '3. Data Sharing and Disclosure',
    content: [
      `We do not sell, rent, or lease your personal information to third parties. However, we may share your data with service providers who assist us in delivering our services.`,
      `Your data may be shared in cases where it is required by law, such as in response to a court order, legal process, or government request.`,
      `In the event of a merger or acquisition, your personal data may be transferred to the new owners. You will be notified if such a change affects the handling of your information.`,
    ],
  },
  {
    title: '4. Data Security',
    content: [
      `We take reasonable measures to protect your personal information from unauthorized access, alteration, or destruction.`,
      `All sensitive information such as payment details is encrypted using SSL (Secure Socket Layer) technology during transmission.`,
      `Despite these precautions, no method of transmission over the internet or electronic storage is 100% secure. Therefore, we cannot guarantee the absolute security of your data.`,
    ],
  },
  {
    title: '5. Your Rights and Choices',
    content: [
      `You have the right to access, update, or delete the personal information we hold about you at any time. Contact us to make any changes or request deletion.`,
      `You may opt out of receiving marketing emails or communications by following the unsubscribe instructions provided in the emails.`,
      `If you wish to disable cookies, you can do so in your browser settings. However, this may impact the functionality of certain features on our website.`,
    ],
  },
  {
    title: '6. Retention of Data',
    content: [
      `We retain your personal information only as long as necessary to fulfill the purposes for which it was collected or as required by law.`,
      `Upon request, we will securely delete or anonymize your data in accordance with applicable legal retention requirements.`,
      `Data that is no longer necessary for our business purposes will be securely destroyed to protect your privacy.`,
    ],
  },
  {
    title: "7. Children's Privacy",
    content: [
      `Our services are not directed to individuals under the age of 13. We do not knowingly collect personal information from children under 13.`,
      `If we become aware that we have inadvertently collected personal information from a child under the age of 13, we will take steps to delete such information.`,
      `Parents or guardians who believe their child may have provided us with personal data should contact us immediately.`,
    ],
  },
  {
    title: '8. Changes to this Privacy Policy',
    content: [
      `We reserve the right to update or modify this Privacy Policy at any time. Any changes will be posted on this page, and the date of the last revision will be indicated at the top.`,
      `We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.`,
      `Your continued use of our services after any changes or modifications to this policy will be considered acceptance of those changes.`,
    ],
  },
  {
    title: '9. Contact Information',
    content: [
      `If you have any questions or concerns about this Privacy Policy or our data handling practices, please contact us at: privacy@example.com.`,
      `You can also write to us at: 123 Wellness Street, Healthy City, HC 12345.`,
      `We will respond to all requests, inquiries, or concerns promptly and in accordance with applicable privacy laws.`,
    ],
  },
];

function PrivacyPolicy() {
  return (
    <Box width="100%" display="flex" alignItems="center" justifyContent="center">
      <Box width="100%" padding="50px 20px" maxWidth="1200px">
        <Typography
          variant="h4"
          fontSize="60px"
          fontWeight="700"
          marginBottom="30px"
          textAlign="center"
          color="#1b2143"
          sx={{
            '@media (max-width:800px)': {
              fontSize: '38px',
            },
            '@media (max-width:400px)': {
              fontSize: '28px',
            },
          }}
        >
          Privacy Policy
        </Typography>

        {/* Map over privacyData to render each section with multiple contents */}
        {privacyData.map((section, index) => (
          <Box key={index} marginBottom="20px">
            <Typography variant="h6" fontWeight="600" marginBottom="10px">
              {section.title}
            </Typography>

            {section.content.map((paragraph, idx) => (
              <Typography key={idx} variant="body1" color="textSecondary" marginBottom="10px">
                {paragraph}
              </Typography>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default PrivacyPolicy;
