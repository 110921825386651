import React from 'react';
import { Box } from '@mui/material';
import Header from '../components/common/Header';
import HomeSection from '../components/Home-section';
import Privacypolicy from '../components/Privacy-policy';
import Footer from '../components/common/Footer';
import BackToTop from '../components/common/Back-to-top';

function Privacypolicypage() {
  return (
    <Box>
      <Header />
      <HomeSection />
      <Privacypolicy />
      <Footer />
      <BackToTop />
    </Box>
  );
}

export default Privacypolicypage;
