import { Box } from '@mui/material';
import React from 'react';
import Header from '../components/common/Header';
import HomeSection from '../components/Home-section';
import Termsconditions from '../components/Terms-conditions';
import Footer from '../components/common/Footer';
import BackToTop from '../components/common/Back-to-top';

const termsconditionpage = () => {
  return (
    <Box>
      <Header />
      <HomeSection />
      <Termsconditions />
      <Footer />
      <BackToTop />
    </Box>
  );
};

export default termsconditionpage;
