// Init
import React, { useState } from 'react';
// Importing Components
import { Box, IconButton } from '@mui/material';
import { Element } from 'react-scroll';
import CloseIcon from '@mui/icons-material/Close';
import Headersection from '../components/common/Header';
import Homesection from '../components/Home-section';
import Features from '../components/Features-section';
import Discovermore from '../components/Discover-more';
import ScreenShotslider from '../components/ScreenShot-slider';
import Footer from '../components/common/Footer';
import BackToTop from '../components/common/Back-to-top';

import { hostUrl } from '../config';
// Home Component
export default function Index() {
  const [showIframe, setShowIframe] = useState(false);

  const handlePlayClick = () => {
    setShowIframe(true);
  };

  const handleCloseIframe = () => {
    setShowIframe(false);
  };

  return (
    <Box>
      <Headersection />
      <Element name="home-section">
        <Homesection />
      </Element>
      <Element name="features-section">
        <Features onPlayClick={handlePlayClick} />
      </Element>
      <Discovermore />
      <Element name="screenshot-section">
        <ScreenShotslider />
      </Element>
      <Footer />
      <BackToTop />

      {showIframe && (
        <Box
          sx={{
            position: 'fixed',
            zIndex: '9999',
            top: '0',
            right: '0',
            padding: '0',
            width: '100%',
            height: '100vh',
            border: '1px solid #e9e9e9',
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <Box
            width="900px"
            display="flex"
            justifyContent="flex-end"
            sx={{
              '@media (max-width:900px)': {
                width: '95%',
              },
            }}
          >
            <IconButton
              onClick={handleCloseIframe}
              sx={{
                // position: 'absolute',
                top: '0',
                right: '0',
                backgroundColor: 'black',
                borderRadius: '50%',
                boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.2)',
                zIndex: 1000,
              }}
            >
              <CloseIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Box>
          <Box
            component="iframe"
            src={hostUrl}
            width="900px"
            height="45vh"
            title="Iframe Content"
            sx={{
              border: 'none',
              backgroundColor: '#fff',

              '@media (max-width:900px)': {
                width: '95%',
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
}
