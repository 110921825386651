/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import YellowShape from '../assets/service-shape-1-1.png';
import VideoBanner from '../assets/video-1-1.png';
import TargetIcon from '../assets/NC Icons - Target.svg';
import BuisnessManicon from '../assets/NC Icons - Businessman.svg';
import Planeicon from '../assets/NC Icons - Plane.svg';

const ServiceIcon = styled(Box)(({ theme }) => ({
  backgroundColor: '#2f4099',
  width: '135px',
  height: '135px',
  borderRadius: '50%',
  textAlign: 'center',
  margin: '0 auto 20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  perspective: '135px',
  transformStyle: 'preserve-3d',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    backgroundImage: `url(${YellowShape})`,
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    transform: 'translateZ(135px) perspective(100px) scale(1.5)',
    transition: 'transform 1s ease',
  },
  '&:hover:before': {
    transform: 'translateZ(0px) perspective(0) scale(1)',
  },
}));

const ServiceTitle = styled(Typography)(({ theme }) => ({
  fontSize: '22px',
  lineHeight: '34px',
  fontWeight: 'bold',
  color: '#1b2143',
  letterSpacing: '0.04em',
  textTransform: 'uppercase',
  marginBottom: '30px',
  '& a': {
    color: '#1b2143',
    transition: 'color 0.4s ease',
    textDecoration: 'none',
  },
  '& a:hover': {
    color: '#f8b426',
  },
}));

function ServiceSection({ onPlayClick }) {
  return (
    <Box
      sx={{
        paddingBottom: '0px',
        paddingTop: '120px',
        display: 'flex',
        flexDirection: 'column',
        gap: '120px',
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '60px',
        }}
      >
        <Box sx={{ textAlign: 'center', marginBottom: '0px' }}>
          <Typography
            variant="h2"
            component="div"
            fontWeight="700"
            color="#1b2143"
            fontSize="60px"
            fontFamily="Titillium Web"
            lineHeight="75px"
            textTransform="uppercase"
            display="flex"
            flexDirection="column"
            sx={{
              '@media (max-width:1200px)': {
                fontSize: '38px',
                lineHeight: '48px',
              },
              '@media (max-width:575px)': {
                flexDirection: 'row',
                fontSize: '32px',
                justifyContent: 'center',
                gap: '5px',
              },
              '@media (max-width:400px)': {
                fontSize: '26px',
              },
            }}
          >
            <span>Advance App</span>
            <span>Features</span>
          </Typography>
        </Box>
        <Grid container spacing={4}>
          <Grid item lg={4} md={4} xs={12}>
            <Box sx={{ textAlign: 'center' }}>
              <ServiceIcon>
                {/* Replace this with actual icon or component */}
                <Box component="img" src={TargetIcon} style={{ width: '58px', height: '58px' }} />
              </ServiceIcon>
              <ServiceTitle variant="h3" fontWeight="700" fontSize="22px" lineHeight="34px">
                <Box
                  component="a"
                  href="#"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    '@media (max-width:575px)': {
                      flexDirection: 'row',
                      justifyContent: 'center',
                      gap: '5px',
                    },
                    '@media (max-width:400px)': {
                      fontSize: '18px',
                    },
                  }}
                >
                  <span>High Creative</span>
                  <span>App Display</span>
                </Box>
              </ServiceTitle>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <Box
              sx={{
                textAlign: 'center',
                borderLeft: '1px solid #e9e9e9',
                borderRight: '1px solid #e9e9e9',
                '@media (max-width:400px)': {
                  width: '100%',
                },
              }}
            >
              <ServiceIcon>
                {/* Replace this with actual icon or component */}
                <Box
                  component="img"
                  src={BuisnessManicon}
                  style={{ width: '58px', height: '58px' }}
                />
              </ServiceIcon>
              <ServiceTitle variant="h3">
                <Box
                  component="a"
                  href="#"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    '@media (max-width:575px)': {
                      flexDirection: 'row',
                      justifyContent: 'center',
                      gap: '5px',
                    },
                    '@media (max-width:400px)': {
                      fontSize: '18px',
                    },
                  }}
                >
                  <span>Unique And</span>
                  <span>Custom Options</span>
                </Box>
              </ServiceTitle>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <Box sx={{ textAlign: 'center' }}>
              <ServiceIcon>
                {/* Replace this with actual icon or component */}
                <Box component="img" src={Planeicon} style={{ width: '58px', height: '58px' }} />
              </ServiceIcon>
              <ServiceTitle variant="h3">
                <Box
                  component="a"
                  href="#"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    '@media (max-width:575px)': {
                      flexDirection: 'row',
                      justifyContent: 'center',
                      gap: '5px',
                    },
                    '@media (max-width:400px)': {
                      fontSize: '18px',
                    },
                  }}
                >
                  <span>Secure</span>
                  <span>Integration</span>
                </Box>
              </ServiceTitle>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Box sx={{ padding: ' 0 ', textAlign: 'center' }}>
        <Container>
          <Box sx={{ marginBottom: '30px' }}>
            <Typography
              variant="h2"
              component="h2"
              sx={{
                fontWeight: '700',
                marginBottom: '20px',
                textTransform: 'uppercase',
                color: '#1b2143',
                fontSize: '60px',
                lineHeight: '75px',
                display: 'flex',
                flexDirection: 'column',
                '@media (max-width:1200px)': {
                  fontSize: '38px',
                  lineHeight: '48px',
                },
                '@media (max-width:575px)': {
                  flexDirection: 'row',
                  fontSize: '32px',
                  justifyContent: 'center',
                  gap: '5px',
                },
                '@media (max-width:450px)': {
                  fontSize: '24px',
                },
                '@media (max-width:355px)': {
                  fontSize: '20px',
                },
              }}
            >
              <span>app integration</span>
              <span>tutorials</span>
            </Typography>
          </Box>
          <Box
            sx={{
              position: 'relative',
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: '#e9e9e9',
              borderRadius: '15px',
              boxShadow: '0px 15px 35px 0px rgba(0, 0, 0, 0.06)',
              padding: '20px',
              '&:hover:before': {
                opacity: 0.3,
              },
              '&:hover .videoLink': {
                color: '#fff',
                backgroundColor: '#1b2143',
              },
              '&:before': {
                content: '""',
                position: 'absolute',
                top: '20px',
                bottom: '25px',
                left: '20px',
                right: '20px',
                backgroundColor: '#1b2143',
                opacity: 0.7,
                borderRadius: '15px',
                transition: 'all 0.4s ease',
              },
            }}
          >
            <Box
              component="img"
              src={VideoBanner}
              alt="Awesome Image"
              style={{ borderRadius: '15px', width: '100%' }}
            />
            <Box
              className="videoLink"
              component="a"
              href="/"
              onClick={(e) => {
                e.preventDefault();
                onPlayClick();
              }}
              sx={{
                textDecoration: 'none',
                width: '145px',
                height: '145px',
                borderRadius: '50%',
                backgroundColor: '#f8b426',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%) scale(1)',
                transformOrigin: 'center center',
                color: '#1b2143',

                transition: 'transform 0.4s ease-in-out',
                '&:hover': {
                  color: '#fff',
                  backgroundColor: '#1b2143',
                  animation: 'zoomInOut 0.4s linear infinite alternate',
                },
                '@keyframes zoomInOut': {
                  '0%': {
                    transform: 'translate(-50%, -50%) scale(1)',
                  },
                  '100%': {
                    transform: ' translate(-50%, -50%) scale(1.2)',
                  },
                },
              }}
            >
              <PlayArrowIcon
                style={{
                  transition: 'transform 0.3s ease',
                  textRendering: 'auto',
                  WebkitFontSmoothing: 'antialiased,',
                  fontSize: '60px',
                }}
              />
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default ServiceSection;
