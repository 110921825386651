import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { Facebook, Twitter, Instagram, Pinterest } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import FooterBgimage from '../../assets/footer-bg-1-1.png';
import FooterLogo from '../../assets/logo-1-1.png';

function Footer() {
  const navigate = useNavigate();

  const handleNavigateTerms = () => {
    navigate('/termsConditions');
  };

  const handleNavigatePrivacy = () => {
    navigate('/privacyPolicy');
  };

  const handleNavigateDelete = () => {
    navigate('/deleteAccount');
  };

  const footerStyle = {
    background: '#2f4099',
    backgroundImage: `url(${FooterBgimage})`,
    backgroundPosition: 'center right',
    backgroundSize: 'cover',
    '@media (max-width: 1199px)': {
      backgroundPosition: 'left center',
    },
  };

  const footerUpperStyle = {
    paddingTop: '110px',
    paddingBottom: '105px',
  };

  const footerBottomStyle = {
    position: 'relative',
    padding: '43px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&::before': {
      content: '""',
      width: '100%',
      height: '1px',
      position: 'absolute',
      top: '0',
      left: '0',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  };

  const footerSocialStyle = {
    display: 'flex',
    alignItems: 'center',
    // marginBottom: '20px',
    gap: '30px',
  };

  const footerSocialLinkStyle = {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: '20px',
    lineHeight: '1em',
    transition: 'all 0.4s ease',
    textDecoration: 'none',
    // marginLeft: '40px',
    '&:first-of-type': {
      marginLeft: '0',
    },
    '&:hover': {
      color: '#f8b426',
    },
  };

  const footerCopyTextStyle = {
    color: '#fff',
    fontSize: '16px',
    fontWeight: 600,
    margin: '0',
    lineHeight: '34px',
    // marginLeft: '55px',
    display: 'inline-block',
    verticalAlign: 'middle',

    '& i': {
      marginRight: '5px',
    },
    '& a': {
      color: '#f8b426',
      textDecoration: 'none',
      transition: 'all 0.4s ease',
      '&:hover': {
        color: '#fff',
      },
    },
  };

  const footerWidgetStyle = {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row-reverse',
    gap: '20px',
    alignItems: 'flex-end',

    // padding: ' 0 65px',
    // maxWidth: '191px',
  };

  const footerBtnStyle = {
    width: '100%',
    maxWidth: '191px',
    height: '61px',
    borderRadius: '30.5px',
    backgroundColor: '#fdfdfd',
    position: 'relative',
    padding: '0 65px 0 65px',
    display: 'flex',
    alignItems: 'center',
    transition: 'all 0.4s ease',
    gap: '30px',
    textDecoration: 'none',
    '& span': {
      fontSize: '12px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      color: '#1b2143',
      display: 'block',
      lineHeight: '1em',
      '&.footer-widget__btn-text-highlight': {
        fontSize: '14px',
        marginTop: '3px',
      },
    },
    '& i': {
      color: '#1b2143',
      fontSize: '20px',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: '40px',
      transition: 'all 0.4s ease',
    },
    '&:hover': {
      backgroundColor: '#f8b426',
      '& i, span': {
        color: '#1b2143',
      },
    },
  };

  return (
    <Box sx={footerStyle}>
      <Box sx={footerUpperStyle}>
        <Box
          sx={{
            padding: '0 20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              width: '1200px',
              '@media (max-width:900px)': {
                flexDirection: 'column',
                // bgcolor: 'red',
                gap: '20px',
              },
            }}
          >
            <Box sx={{ ...footerWidgetStyle, alignItems: 'flex-start' }}>
              <Link href="./Nutrition Coach.html" sx={{ display: 'flex', alignItems: 'center' }}>
                <img src={FooterLogo} width="132" alt="Logo" />
              </Link>
            </Box>

            <Box
              sx={{
                ...footerWidgetStyle,
                alignItems: 'center',
                '@media (max-width:900px)': {
                  alignItems: 'center',
                },
              }}
            >
              <Link href="/" sx={footerBtnStyle}>
                <i className="fa fa-play" />
                <span>
                  Get in
                  <span className="footer-widget__btn-text-highlight" style={{ whiteSpace: 'pre' }}>
                    Google Play
                  </span>
                </span>
              </Link>
              <Typography
                color="#fff"
                fontSize="18px"
                fontWeight="500"
                onClick={handleNavigateTerms}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    color: '#f8b426',
                  },
                }}
              >
                Terms & Conditions
              </Typography>
              <Typography
                color="#fff"
                fontSize="18px"
                fontWeight="500"
                onClick={handleNavigatePrivacy}
                sx={{
                  cursor: 'pointer',

                  '&:hover': {
                    color: '#f8b426',
                  },
                }}
              >
                Privacy Policy
              </Typography>
              <Typography
                color="#fff"
                fontSize="18px"
                fontWeight="500"
                onClick={handleNavigateDelete}
                sx={{
                  cursor: 'pointer',

                  '&:hover': {
                    color: '#f8b426',
                  },
                }}
              >
                Delete Account
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={footerBottomStyle}>
        <Box
          sx={{
            padding: '0 0px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '1200px',
            gap: '55px',
            '@media (max-width:1200px)': {
              width: '100%',
              padding: '0 20px',
            },
            '@media (max-width:900px)': {
              flexDirection: 'column',
              // bgcolor: 'red',
              gap: '20px',
            },
          }}
        >
          <Box sx={footerSocialStyle}>
            <Link href="/" sx={footerSocialLinkStyle}>
              <Facebook />
            </Link>
            <Link href="/" sx={footerSocialLinkStyle}>
              <Twitter />
            </Link>
            <Link href="/" sx={footerSocialLinkStyle}>
              <Instagram />
            </Link>
            <Link href="/" sx={footerSocialLinkStyle}>
              <Pinterest />
            </Link>
          </Box>
          <Typography
            sx={{
              ...footerCopyTextStyle,
              '@media (max-width:900px)': {
                textAlign: 'center',
              },
            }}
          >
            <i className="fa fa-copyright" /> copyright 2021 by{' '}
            <Link href="/">Nutrition Coach</Link>
          </Typography>
          <Typography
            sx={{
              ...footerCopyTextStyle,
              '@media (max-width:900px)': {
                textAlign: 'center',
              },
            }}
          >
            ADDRESS: Nutrition Coach 137 J 1, Wapda town Lahore
          </Typography>
          <Typography
            sx={{
              ...footerCopyTextStyle,
              '@media (max-width:900px)': {
                textAlign: 'center',
              },
            }}
          >
            CONTACT: +923001111446
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
