// Import necessary modules
import { Box } from '@mui/material';
import React from 'react';
import Bannerimg from '../assets/banner-bg-1-1.png';

// Component
export default function HomeSection() {
  return (
    <Box
      width="100%"
      height="77vh"
      display="flex"
      top="0"
      sx={{
        '@media (max-width:1400px)': {
          height: 'auto',
        },
        '@media (max-width:768px)': {
          backgroundImage: 'linear-gradient(-121deg, #222c67 0%, #2c3b8d 50%, #384eb9 100%)',
          height: '40vh',
        },
      }}
    >
      <Box
        component="img"
        width="100%"
        height="100%"
        src={Bannerimg}
        alt=""
        sx={{
          // backgroundImage: `url(${Bannerimg})`,
          // backgroundSize: 'cover',
          top: '0',
          backgroundRepeat: 'no-repeat',
          '@media (max-width:768px)': {
            display: 'none',
          },
        }}
      />
    </Box>
  );
}
