/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import TelegramIcon from '@mui/icons-material/Telegram';
import emailjs from 'emailjs-com'; // Import EmailJS

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  backgroundColor: '#f2f2f2',
  borderRadius: '5px',
  margin: '0',
  '& .MuiOutlinedInput-root': {
    height: '54px',
    '& fieldset': {
      border: 'none', // No border by default
    },
    '&:hover fieldset': {
      borderColor: '#69727d', // Border color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: '#69727d', // Border color on focus
      transition: '0.9s',
    },
    '& .MuiOutlinedInput-input': {
      padding: '0 16px',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '30px',
      color: '#1E1E1E',
    },
  },
}));

export function Contactform() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    message: '',
    phone: '',
    city: '',
    state: '',
    zip: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false); // For submission status

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // EmailJS configuration
    const serviceID = 'your_service_id'; // Replace with your EmailJS service ID
    const templateID = 'your_template_id'; // Replace with your EmailJS template ID
    const userID = 'your_user_id'; // Replace with your EmailJS user ID

    emailjs.send(serviceID, templateID, formData, userID).then(
      (response) => {
        setIsSubmitted(true); // Set to true on success
      },
      (error) => {
        setIsSubmitted(false);
      },
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      padding="40px"
      sx={{ fontFamily: 'Montserrat' }}
      width="100%"
    >
      <Typography
        variant="h4"
        gutterBottom
        fontSize="60px"
        lineHeight="75px"
        color="#1b2143"
        fontWeight="700"
      >
        Contact Us
      </Typography>
      {isSubmitted ? (
        <Typography variant="h6" color="green">
          Your message has been successfully sent!
        </Typography>
      ) : (
        <form onSubmit={handleSubmit} style={{ width: '1200px' }}>
          <Box
            width="100%"
            padding="40px 0"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              width="100%"
              bgcolor="white"
              gap="10px"
              display="flex"
              flexDirection="column"
              sx={{
                '@media (max-width:1424px)': {
                  width: '80%',
                },
              }}
            >
              <Box
                width="100%"
                display="flex"
                gap="10px"
                sx={{
                  '@media (max-width:768px)': {
                    flexDirection: 'column',
                  },
                }}
              >
                <StyledTextField
                  fullWidth
                  placeholder="Name*"
                  autoComplete="off"
                  margin="normal"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                <StyledTextField
                  fullWidth
                  placeholder="Email*"
                  autoComplete="off"
                  margin="normal"
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <StyledTextField
                  fullWidth
                  placeholder="Phone Number"
                  autoComplete="off"
                  margin="normal"
                  type="number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </Box>

              <StyledTextField
                fullWidth
                placeholder="Address"
                autoComplete="off"
                margin="normal"
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
              <Box
                width="100%"
                display="flex"
                gap="10px"
                sx={{
                  '@media (max-width:768px)': {
                    flexDirection: 'column',
                  },
                }}
              >
                <StyledTextField
                  fullWidth
                  placeholder="City"
                  autoComplete="off"
                  margin="normal"
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
                <StyledTextField
                  fullWidth
                  placeholder="State"
                  autoComplete="off"
                  margin="normal"
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                />
                <StyledTextField
                  fullWidth
                  placeholder="Zip Code"
                  autoComplete="off"
                  margin="normal"
                  type="number"
                  name="zip"
                  value={formData.zip}
                  onChange={handleChange}
                />
              </Box>
              <TextField
                fullWidth
                multiline
                minRows={7}
                name="message"
                placeholder="Message*"
                value={formData.message}
                onChange={handleChange}
                InputProps={{
                  sx: {
                    '& textarea': {
                      resize: 'vertical',
                      overflow: 'auto',
                    },
                  },
                }}
                sx={{
                  border: '1px solid #f2f2f2',
                  bgcolor: '#f2f2f2',
                  borderRadius: '5px',
                  '& .MuiOutlinedInput-root': {
                    padding: '14px 8px 8px 14px',
                    fontSize: '16px',
                    fontWeight: '400',
                    color: '#000000',
                    boxShadow: '0px 1px 2px 0px #1018280D',
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#69727d',
                    },
                    '& fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: '1px solid #69727d',
                      transition: '0.9s',
                    },
                  },
                }}
              />
            </Box>
            <Box
              width="1200px"
              padding="40px 0"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              sx={{
                '@media (max-width:1424px)': {
                  width: '80%',
                },
              }}
            >
              <Button
                type="submit"
                sx={{
                  fontSize: '11px',
                  fontWeight: 'bold',
                  lineHeight: '1.9em',
                  letterSpacing: '1.6px',
                  borderStyle: 'solid',
                  borderWidth: '0 0 3px 0',
                  borderRadius: '8px',
                  borderColor: '#7697e9',
                  bgcolor: '#3444fa',
                  color: '#ffffff',
                  padding: '15px 30px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <TelegramIcon sx={{ fontSize: '20px' }} /> SUBMIT
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Box>
  );
}

export default Contactform;
