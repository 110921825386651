/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react';
import { Box, Typography, IconButton, useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Import Swiper styles
import 'swiper/css/navigation'; // Import Swiper navigation styles
import 'swiper/css/pagination'; // Import Swiper pagination styles
import 'swiper/css/autoplay'; // Import Swiper autoplay styles
import { Autoplay } from 'swiper/modules';
import EastIcon from '@mui/icons-material/East';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

// Images
import appScreen1 from '../assets/app-screen-1-1.png';
import appScreen2 from '../assets/app-screen-1-2.png';
import appScreen3 from '../assets/app-screen-1-3.png';
import appScreen4 from '../assets/app-screen-1-4.png';
import appScreen5 from '../assets/app-screen-1-5.png';
import AppScreenMoc from '../assets/app-screen-moc-1-1.png';

function ScreenShotslider() {
  const screens = [
    appScreen1,
    appScreen2,
    appScreen3,
    appScreen4,
    appScreen5,
    appScreen1,
    appScreen2,
    appScreen3,
    appScreen4,
    appScreen5,
  ];

  const swiperRef = useRef(null);
  const isMobile = useMediaQuery('(max-width:640px)');

  const handleNextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrevSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleDotCLick = (index) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideToLoop(index);
    }
  };
  return (
    <Box
      id="screens"
      sx={{
        paddingTop: '30px',
        paddingBottom: '90px',
        position: 'relative',
        // backgroundColor: '#f9f9f9',
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          maxWidth: '1590px',
          margin: '0 auto',
          position: 'relative',
          paddingTop: '80px',
          paddingBottom: '120px',
          display: 'flex',
          flexDirection: 'column',
          gap: '37px',
          '@media (max-width:1024px)': {
            paddingBottom: '40px',
          },
        }}
      >
        <Typography
          variant="h2"
          sx={{
            // marginBottom: '10px',
            fontSize: '60px',
            lineHeight: '75px',
            fontWeight: '700',
            position: 'relative',
            textTransform: 'uppercase',
            zIndex: 2,
            marginBottom: '100px',
            color: '#1b2143',
            displa: 'flex',
            flexdirection: 'column',
            '@media (max-width:1200px)': {
              fontSize: '38px',
              lineHeight: '48px',
              marginBottom: '50px',
            },
            '@media (max-width:575px)': {
              flexDirection: 'row',
              fontSize: '32px',
              justifyContent: 'center',
            },
          }}
        >
          <span>Appyng App</span>
          <span>ScreenShot</span>
        </Typography>

        <Box
          sx={{
            position: 'absolute',
            top: '60%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '400px',
            height: '100%',
            backgroundImage: `url(${AppScreenMoc})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            zIndex: 1,
            '@media (max-width:1440px)': {
              width: '360px',
              // height: '',
            },
            '@media (max-width:1340px)': {
              width: '320px',
              height: '100%',
            },
            '@media (max-width:1200px)': {
              width: '280px',
              height: '100%',
            },
            '@media (max-width:1024px)': {
              display: 'none',
            },
          }}
        />
        {/* Swiper Carousel */}
        <Swiper
          ref={swiperRef}
          modules={[Autoplay]}
          spaceBetween={30}
          slidesPerView={5}
          loop
          autoplay={{
            delay: 3000, // Pause for 3 seconds
            disableOnInteraction: false, // Keep autoplay running after user interaction
          }}
          speed={1000}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 5,
            },
          }}
          style={{
            position: 'relative',
            zIndex: 2,
            width: '100%',
            padding: '0 20px',
          }}
        >
          {screens.map((screen, index) => (
            <SwiperSlide key={index}>
              <Box
                component="img"
                src={screen}
                alt="App Screenshot"
                sx={{
                  width: '264px',
                  height: '447px',
                  borderRadius: '15px',
                  // bgcolor: 'red',
                  '@media (max-width:1440px)': {
                    width: '240px',
                    height: '100%',
                  },
                  '@media (max-width:1340px)': {
                    width: '220px',
                    height: '100%',
                  },
                  '@media (max-width:1200px)': {
                    width: '180px',
                    height: '100%',
                  },
                  '@media (max-width:1024px)': {
                    width: '264px',
                    height: '100%',
                  },
                  '@media (max-width:900px)': {
                    width: '220px',
                    height: '100%',
                  },
                  '@media (max-width:750px)': {
                    width: '200px',
                    height: '100%',
                  },
                  '@media (max-width:640px)': {
                    width: '520px',
                    height: '100%',
                  },
                  '@media (max-width:550px)': {
                    width: '95%',
                    height: '100%',
                  },
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        {isMobile ? (
          <Box
            sx={{
              position: 'absolute',
              bottom: '-35px',
              left: '50%',
              transform: 'translateX(-50%)',
              display: 'flex',
              gap: '20px',
              zIndex: 2,
            }}
          >
            {/* Back Arrow */}
            <IconButton
              onClick={handlePrevSlide}
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '3px',
                backgroundColor: '#2f4099',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#f8b426',
                },
              }}
            >
              <KeyboardBackspaceIcon />
            </IconButton>

            {/* Next Arrow */}
            <IconButton
              onClick={handleNextSlide}
              sx={{
                borderRadius: '3px',

                width: '40px',
                height: '40px',
                backgroundColor: '#2f4099',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#f8b426',
                },
              }}
            >
              <EastIcon />
            </IconButton>
          </Box>
        ) : (
          <Box
            sx={{
              position: 'absolute',
              bottom: '-35px',
              left: '50%',
              transform: 'translateX(-50%)',
              display: 'flex',
              gap: '5px',
              zIndex: 2,
            }}
          >
            {[0, 1, 2].map((dot, index) => (
              <Box
                key={index}
                onClick={() => handleDotCLick(index)}
                sx={{
                  width: '7px',
                  height: '7px',
                  borderRadius: '50%',
                  backgroundColor: '#1b2143',
                  opacity: 0.2,
                  transition: 'all 0.4s ease',
                  '&:hover': {
                    backgroundColor: '#f8b426',
                    opacity: 1,
                  },
                  '&.swiper-pagination-bullet-active': {
                    backgroundColor: '#f8b426',
                    opacity: 1,
                  },
                }}
              />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ScreenShotslider;
