/* eslint-disable import/no-unresolved */
// Init
import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Routes
import IndexMian from '../containers/Index';
import Termsconditionpage from '../containers/Terms&condition.page';
import Privacypolicypage from '../containers/Privacy-policy.page';
import DeleteAccount from '../containers/DeleteAoount.page';
import Contactpage from '../containers/Contact.page';

function Index() {
  return (
    <Routes>
      <Route path="/" element={<IndexMian />} />
      <Route path="/termsConditions" element={<Termsconditionpage />} />
      <Route path="/privacyPolicy" element={<Privacypolicypage />} />
      <Route path="/contactpage" element={<Contactpage />} />
      <Route path="/deleteAccount" element={<DeleteAccount />} />
    </Routes>
  );
}
export default Index;
