/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Box, AppBar, Toolbar, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; // Menu icon for mobile view
import { Link as ScrollLink } from 'react-scroll';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo-1-1.png';

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // For handling the menu open state on mobile
  const location = useLocation();
  const navigate = useNavigate();
  const isHomePage = location.pathname === '/';

  const handleNavigateHome = () => {
    navigate('/');
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Handle menu opening and closing for mobile
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="138px"
      backgroundColor="transparent"
      position="absolute"
      zIndex="1000"
    >
      {/* First Nav (Hidden on scroll) */}
      <AppBar
        position="static"
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          boxShadow: 'none',
          transition: 'transform 0.4s ease',
          transform: isScrolled ? 'translateY(0%)' : 'translateY(0)',
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            width: '1170px',
            height: '100%',
            justifyContent: 'flex-start',
            gap: '30%',
            '@media (max-width:1170px)': {
              width: '100%',
              justifyContent: 'space-between',
              gap: '0',
              padding: '0 24px 0 24px',
            },
          }}
        >
          {/* Logo */}
          <Box
            display="flex"
            alignItems="center"
            height="100%"
            onClick={handleNavigateHome}
            sx={{ cursor: 'pointer' }}
          >
            <img src={logo} alt="Awesome Logo" width="97" />
          </Box>

          {/* Desktop Menu */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
            <ul style={{ display: 'flex', listStyle: 'none', padding: 0, margin: 0, gap: '35px' }}>
              <li>
                {isHomePage ? (
                  <ScrollLink
                    to="home-section"
                    smooth
                    duration={1500}
                    style={{
                      textDecoration: 'none',
                      color: 'rgb(255,255,255)',
                      fontWeight: '400',
                      fontSize: '16px',
                      textTransform: 'capitalize',
                      fontFamily: '"Titillium Web", sans-serif',
                      cursor: 'pointer',
                    }}
                  >
                    Home
                  </ScrollLink>
                ) : (
                  <Link to="/" style={{ textDecoration: 'none', color: 'rgb(255,255,255)' }}>
                    Home
                  </Link>
                )}
              </li>
              <li>
                <ScrollLink
                  to="features-section"
                  smooth
                  duration={1500}
                  style={{
                    textDecoration: 'none',
                    color: 'rgb(255,255,255)',
                    fontWeight: '400',
                    fontSize: '16px',
                    textTransform: 'capitalize',
                    fontFamily: '"Titillium Web", sans-serif',
                    cursor: 'pointer',
                  }}
                >
                  Features
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  to="screenshot-section"
                  smooth
                  duration={1500}
                  style={{
                    textDecoration: 'none',
                    color: 'rgb(255,255,255)',
                    fontWeight: '400',
                    fontSize: '16px',
                    textTransform: 'capitalize',
                    fontFamily: '"Titillium Web", sans-serif',
                    cursor: 'pointer',
                  }}
                >
                  Screenshot
                </ScrollLink>
              </li>
              <li>
                <a
                  href="/contactpage"
                  style={{
                    textDecoration: 'none',
                    color: 'rgb(255,255,255)',
                    fontWeight: '400',
                    fontSize: '16px',
                    textTransform: 'capitalize',
                    fontFamily: '"Titillium Web", sans-serif',
                    cursor: 'pointer',
                  }}
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </Box>

          {/* Mobile Menu Icon (Visible on small screens) */}
          <IconButton
            sx={{ display: { xs: 'flex', md: 'none' }, color: 'white' }}
            onClick={handleMenuClick}
          >
            <MenuIcon sx={{ fontSize: '50px' }} />
          </IconButton>

          {/* Dropdown Menu for Mobile */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              style: { width: '100%', marginLeft: '0', marginTop: '40px' },
            }}
          >
            <MenuItem onClick={handleMenuClose}>
              {isHomePage ? (
                <ScrollLink
                  to="home-section"
                  smooth
                  duration={1500}
                  style={{ textDecoration: 'none', color: '#000' }}
                >
                  Home
                </ScrollLink>
              ) : (
                <Link to="/" style={{ textDecoration: 'none', color: '#000' }}>
                  Home
                </Link>
              )}
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <ScrollLink
                to="features"
                smooth
                duration={1500}
                style={{ textDecoration: 'none', color: '#000' }}
              >
                Features
              </ScrollLink>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <ScrollLink
                to="screenshot-section"
                smooth
                duration={1500}
                style={{ textDecoration: 'none', color: '#000' }}
              >
                Screenshot
              </ScrollLink>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <Link to="/contactpage" style={{ textDecoration: 'none', color: '#000' }}>
                Contact Us
              </Link>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      {/* Sticky Nav (Appears on scroll) */}
      <AppBar
        position="fixed"
        sx={{
          width: '100%',
          height: '110px',
          bgcolor: '#000000',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transform: isScrolled ? 'translateY(0)' : 'translateY(-130%)',
          transition: 'transform 0.4s ease',
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            width: '1170px',
            height: '100%',
            gap: '30%',
            '@media (max-width:1170px)': {
              width: '100%',
              justifyContent: 'space-between',
              gap: '0',
              padding: '0 24px 0 24px',
            },
          }}
        >
          {/* Sticky Logo */}
          <Box
            display="flex"
            alignItems="center"
            height="100%"
            onClick={handleNavigateHome}
            sx={{ cursor: 'pointer' }}
          >
            <img src={logo} alt="Awesome Logo" width="97" />
          </Box>

          {/* Sticky Desktop Menu */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
            <ul style={{ display: 'flex', listStyle: 'none', padding: 0, margin: 0, gap: '35px' }}>
              <li>
                {isHomePage ? (
                  <ScrollLink
                    to="home-section"
                    smooth
                    duration={1500}
                    style={{
                      textDecoration: 'none',
                      color: '#fff',
                      fontWeight: '400',
                      fontSize: '16px',
                      textTransform: 'capitalize',
                      fontFamily: '"Titillium Web", sans-serif',
                      cursor: 'pointer',
                    }}
                  >
                    Home
                  </ScrollLink>
                ) : (
                  <Link to="/" style={{ textDecoration: 'none', color: '#fff' }}>
                    Home
                  </Link>
                )}
              </li>
              <li>
                <ScrollLink
                  to="screenshot-section"
                  smooth
                  duration={1500}
                  style={{
                    textDecoration: 'none',
                    color: '#fff',
                    fontWeight: '400',
                    fontSize: '16px',
                    textTransform: 'capitalize',
                    fontFamily: '"Titillium Web", sans-serif',
                    cursor: 'pointer',
                  }}
                >
                  Features
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  to="screenshot-section"
                  smooth
                  duration={1500}
                  style={{
                    textDecoration: 'none',
                    color: '#fff',
                    fontWeight: '400',
                    fontSize: '16px',
                    textTransform: 'capitalize',
                    fontFamily: '"Titillium Web", sans-serif',
                    cursor: 'pointer',
                  }}
                >
                  Screenshot
                </ScrollLink>
              </li>
              <li>
                <a
                  href="/contactpage"
                  style={{
                    textDecoration: 'none',
                    color: 'rgb(255,255,255)',
                    fontWeight: '400',
                    fontSize: '16px',
                    textTransform: 'capitalize',
                    fontFamily: '"Titillium Web", sans-serif',
                    cursor: 'pointer',
                  }}
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </Box>

          {/* Sticky Mobile Menu Icon */}
          <IconButton
            sx={{ display: { xs: 'flex', md: 'none' }, color: 'white' }}
            onClick={handleMenuClick}
          >
            <MenuIcon sx={{ fontSize: '50px' }} />
          </IconButton>

          {/* Sticky Mobile Dropdown Menu */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              style: { width: '100%', marginLeft: '0', marginTop: '40px' },
            }}
          >
            <MenuItem onClick={handleMenuClose}>
              {isHomePage ? (
                <ScrollLink
                  to="home-section"
                  smooth
                  duration={1500}
                  style={{ textDecoration: 'none', color: '#000' }}
                >
                  Home
                </ScrollLink>
              ) : (
                <Link to="/" style={{ textDecoration: 'none', color: '#000' }}>
                  Home
                </Link>
              )}
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <ScrollLink
                to="features-section"
                smooth
                duration={1500}
                style={{ textDecoration: 'none', color: '#000' }}
              >
                Features
              </ScrollLink>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <ScrollLink
                to="screenshot-section"
                smooth
                duration={1500}
                style={{ textDecoration: 'none', color: '#000' }}
              >
                Screenshot
              </ScrollLink>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <Link to="/contactpage" style={{ textDecoration: 'none', color: '#000' }}>
                Contact Us
              </Link>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
