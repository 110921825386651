let baseUrl = 'http://localhost:5000/api';
let hostUrl = 'http://localhost:3001';

if (window.location.host === 'nutritioncoach-dev.falconweb.app') {
  baseUrl = 'https://nutritioncoach-api-dev.falconweb.app/api';
  hostUrl = 'https://nutritioncoach-api-dev.falconweb.app';
} else if (window.location.host === 'reactapp-qa.falconweb.app') {
  baseUrl = 'https://reactapp-api-qa.falconweb.app/api';
  hostUrl = 'https://reactapp-api-dev.falconweb.app';
} else if (window.location.host === 'reactapp-staging.falconweb.app') {
  baseUrl = 'https://reactapp-api-staging.falconweb.app/api';
  hostUrl = 'https://reactapp-api-dev.falconweb.app';
} else if (window.location.host === 'nutritioncoachofficial.com') {
  baseUrl = 'https://api.nutritioncoachofficial.com/api';
  hostUrl = 'https://api.nutritioncoachofficial.com';
}

module.exports = { baseUrl, hostUrl };
