import React from 'react';
import { Box } from '@mui/material';
import Header from '../components/common/Header';
import HomeSection from '../components/Home-section';
import ContactForm from '../components/Contact-form';
import Footer from '../components/common/Footer';

function Contactpage() {
  return (
    <Box>
      <Header />
      <HomeSection />
      <ContactForm />
      <Footer />
    </Box>
  );
}

export default Contactpage;
