/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { TextField, Button, Container, Box, Typography } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  backgroundColor: '#f2f2f2',
  borderRadius: '5px',
  margin: '0',
  '& .MuiOutlinedInput-root': {
    height: '54px',
    '& fieldset': {
      border: 'none', // No border by default
    },
    '&:hover fieldset': {
      borderColor: '#69727d', // Border color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: '#69727d', // Border color on focus
      transition: '0.9s',
    },
    '& .MuiOutlinedInput-input': {
      padding: '0 16px',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '30px',
      color: '#1E1E1E',
    },
  },
}));
function DeleteAccount() {
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const navigate = useNavigate();

  const handleDeleteAccount = () => {
    if (isEmailValid) {
      toast.success(
        'Your account will be deleted shortly. Our representative will contact you soon.',
        {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );

      setEmail('');
      navigate('/');
    } else {
      toast.error('Please enter a valid email!', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Email validation function
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setIsEmailValid(validateEmail(emailValue));
  };

  const handleNavigateHome = () => {
    navigate('/'); // Assuming "/" is the home route
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Typography variant="h4" gutterBottom>
          Delete Your Account
        </Typography>
        <Typography
          width="100%"
          lineHeight="40px"
          color={!isEmailValid && email ? 'error' : 'inherit'}
        >
          {email && !isEmailValid ? 'Invalid email format' : ''}
        </Typography>
        <StyledTextField
          placeholder="Email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={handleEmailChange}
          sx={{ mb: 2 }}
          error={email && !isEmailValid}
        />

        <Button
          variant="contained"
          color="error"
          onClick={handleDeleteAccount}
          fullWidth
          disabled={!isEmailValid || !email} // Disable when invalid or empty
          // sx={{ mb: 2 }}
        >
          Delete Account
        </Button>
      </Box>

      {/* Toast Container for notifications */}
      <ToastContainer />
    </Container>
  );
}

export default DeleteAccount;
